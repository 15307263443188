<script>
    import classNames from 'classnames'
    import Title from 'components/Title'
    import Image from 'components/Image'
    export let title
    export let subtitle
    export let backgroundImage
    export let fill
    export let blank
    export let withPattern = false

    let fallbackSlot
</script>

<style global>
    :global(.Hero) {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6rem 0;
        min-height: 40vh;
        margin-bottom: calc(var(--layout-gutter) * 2);
    }

    @media (min-width: 768px) {
        :global(.Hero) {
            min-height: 60vh;
        }
    }

    :global(.Hero-background) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    :global(.Hero-backgroundImage) {
        flex: 1;
    }

    :global(.Hero-content) {
        background: rgba(255, 255, 255, 0.85);
        padding: 2rem;
        position: relative;
        width: 45rem;
        max-width: 80vw;
    }

    :global(.Hero--blank) :global(.Hero-content) {
        background: none;
    }

    :global(.Hero--fill),
    :global(.Hero--fill) :global(.Hero-content) {
        padding: 0;
    }

    @media (max-width: 575px) {
        :global(.Hero-content) {
            padding: 0;
        }
    }</style>

<section
    class={classNames('Hero', 'layout-fill', {
        'Hero--fill': fill,
        'Hero--blank': blank
    })}>
    <div class="Hero-background l-columns-12 layout-center">
        {#if backgroundImage}
            <Image
                class="Hero-backgroundImage"
                cover
                {...backgroundImage}
                {withPattern} />
        {/if}
        <slot name="background" />
    </div>
    <div
        class={classNames('Hero-content', {
            'l-columns-12 layout-center': fill,
            hide: !(title || subtitle || !fallbackSlot)
        })}>
        <header class="Hero-header">
            {#if title}
                <Title class="Hero-title">{title}</Title>
            {/if}
            {#if subtitle}
                <Title level={2} class="Hero-subtitle">{subtitle}</Title>
            {/if}
        </header>
        <slot>
            <div bind:this={fallbackSlot} />
        </slot>
    </div>
</section>
