<script>
    import classNames from 'classnames'
    import { Label } from './index'
    import uuid from 'uuid'
    export let name = ''
    export let value = null
    export let label = ''
    export let options = []
    export let columned = null
    export let id = uuid()
    let className
    export { className as class }
</script>

<style global>
    :global(.RadioList) {
        text-align: left;
        border: none;
        margin: 0;
        padding: 0;
    }
    :global(.RadioList-legend) {
        margin: 0 0 0 -2px;
    }
    :global(.RadioList-item) {
        position: relative;
    }
    :global(.RadioList-input) {
        position: absolute;
        left: -999em;
    }
    :global(.RadioList-label) {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    :global(.RadioList-icon) {
        flex: 0 0 auto;
        display: block;
        border-radius: 1em;
        width: 1.25em;
        height: 1.25em;
        margin: 0.2em;
        margin-right: 0.5em;
        box-shadow: 0 0 0 1px currentColor;
        background: var(--color-white);
        position: relative;
    }
    :global(input:checked) + :global(.RadioList-label) :global(.RadioList-icon::before) {
        /* box-shadow: 0 0 2px 1px currentColor, 0 0 0 1px currentColor,
            inset 0 0 0 0.2em var(--color-white), inset 0 0 0 1em currentColor; */
        content: '';
        width: 0.75em;
        height: 0.75em;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1em;
        background: currentColor;
    }
    :global(input:focus:not(:disabled)) + :global(.RadioList-label) :global(.RadioList-icon),
    :global(input:hover:not(:disabled)) + :global(.RadioList-label) :global(.RadioList-icon) {
        background-color: var(--color-hover);
    }
    :global(input:disabled) + :global(.RadioList-label) :global(.RadioList-icon) {
        color: rgba(0, 0, 0, 0.1);
    }
    :global(.RadioList--columned) :global(.RadioList-items) {
        grid-column-gap: 1em;
        -moz-column-gap: 1em;
             column-gap: 1em;
        -moz-column-width: 150px;
             column-width: 150px;
    }
    :global(.RadioList--columned) :global(.RadioList-item) {
        display: inline-block;
        width: 100%;
    }</style>

<fieldset
    class={classNames('RadioList', { 'RadioList--columned': columned }, className)}
    on:change>
    {#if label}
        <legend class="RadioList-legend text-label">{label}</legend>
    {/if}
    <div class="RadioList-items">
        {#each options as option}
            <div class="RadioList-item">
                <input
                    class="RadioList-input"
                    type="radio"
                    {name}
                    disabled={option.disabled}
                    value={option.value}
                    id={option.id || `${id}-${option.value}`}
                    bind:group={value} />
                <Label
                    class="RadioList-label"
                    for={option.id || `${id}-${option.value}`}>
                    <span class="RadioList-icon" />
                    <span class="RadioList-text">{option.text}</span>
                </Label>
            </div>
        {/each}
    </div>
</fieldset>
