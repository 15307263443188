<script>
    import classNames from 'classnames'
    import ConditionalLink from 'utils/ConditionalLink'
    import { isString } from 'utils/utils.js'
    let className
    export { className as class }
    export let items = []
    export let inline = null
</script>

<style global>
    :global(.DefinitionList) {
    }

    :global(.DefinitionList-item:first-child) {
        margin-top:0px;
    }

    :global(.DefinitionList-item) {
        overflow: hidden;
    }

    :global(.DefinitionList-title) {
        flex: 0 0 30%;
        width: 30%;
        float: left;
        font-weight: var(--font-weight-bold);
    }

    :global(.DefinitionList-definition) {
        flex: 1 0 35%;
        width: 70%;
        margin: 0;
        float: right;
        max-width:400px;
        word-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

    :global(.DefinitionList--inline) :global(.DefinitionList-item) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    :global(.DefinitionList--inline) :global(.DefinitionList-title),
    :global(.DefinitionList--inline) :global(.DefinitionList-definition) {
        float: none;
        width: auto;
    }

    :global(.DefinitionList--inline) :global(.DefinitionList-definition) {
        padding-left: var(--layout-gutter);
    }

    @media (max-width: 767px) {
        :global(.DefinitionList--inline) :global(.DefinitionList-title) {
            flex-basis: 100%;
        }

        :global(.DefinitionList--inline) :global(.DefinitionList-definition) {
            padding-left: 0;
            flex: 1 1 100%;
            margin: 5px 0;
        }
    }</style>

<div
    class={classNames('DefinitionList', className, {
        'DefinitionList--inline': inline
    })}>
    {#each items as { title, definition, definitions }}
        <dl class="DefinitionList-item">
            <dt class="DefinitionList-title">{title}</dt>
            {#if definitions}
                {#each definitions as definition}
                    <dd class="DefinitionList-definition">
                        <ConditionalLink
                            if={definition && definition.to}
                            linkProps={{ ...definition }}>
                            {(definition && definition.text) || (isString(definition) && definition) || ''}
                        </ConditionalLink>
                    </dd>
                {/each}
            {:else}
                <dd class="DefinitionList-definition">
                    <ConditionalLink
                        if={definition && definition.to}
                        linkProps={{ ...definition }}>
                        {(definition && definition.text) || (isString(definition) && definition) || ''}
                    </ConditionalLink>
                </dd>
            {/if}
        </dl>
    {/each}
</div>
