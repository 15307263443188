<script context="module">
import PageBlock from 'components/PageBlock'
import { Grid, GridCell } from 'components/Grid'
import { PropertyList, PropertyRow } from 'components/PropertyList'
import GalleryCard from 'components/GalleryCard'
import Link from 'components/Link'
import { formatNumber, upperCaseWords } from 'utils/utils.js'

export async function preload(block, fetch) {
    block.items = await Promise.all(
        block.items.map(async (item) => {
            let query = {
                command: item.command,
                plugin_id: item.plugin_id
            };
            let querystring = Object.keys(query).map((key) => `${key}=${query[key]}` ).join("&");
            let {
                require_subscription,
                pricing,
                supported_file_types,
            } = await fetch(`skills/pricing.json?${querystring}`, {
                credentials: "include",
            }).then((data) => data.json());

            return { ...item, require_subscription, pricing, supported_file_types };
        })
    );
    return block;
}
</script>

<script>
export let block
let { title } = block
</script>

<PageBlock theme=turquoise fill title={title}>
    <Grid withGap class="SkillGalleryBlock-cards">
        {#each block.items as item}
            <GridCell widths={{ l: '1of3', m:'1of2', s:'1of1' }}>
                <GalleryCard 
                    {...item}>
                    <PropertyList fill>
                        <PropertyRow>
                            <div slot="name">
                                Command
                            </div>
                            <div slot="value">
                                {upperCaseWords(item.command)}
                            </div>
                        </PropertyRow>
                        <PropertyRow>
                            <div slot="name">
                                Supported File Types
                            </div>
                            <div slot="value">
                                <Link>{formatNumber(item.supported_file_types)}</Link>
                            </div>
                        </PropertyRow>
                        <PropertyRow>
                            <div slot="name">
                                Pricing
                            </div>
                            <div slot="value">
                                {item.pricing}
                            </div>
                        </PropertyRow>
                        <PropertyRow noborder>
                            <div slot="name">
                                Subscription Required
                            </div>
                            <div slot="value">
                                {item.require_subscription ? 'Yes' : 'No'}
                            </div>
                        </PropertyRow>
                    </PropertyList>

                </GalleryCard>
            </GridCell>
        {/each}
    </Grid>

</PageBlock>

<style global>
    :global(.SkillGalleryBlock-cards) {
        margin-left: 50px;
        margin-right: 50px;
    }</style>