<script>
    import { createEventDispatcher } from 'svelte'
    import classNames from 'classnames'
    import Card from 'components/Card'
    import Button from 'components/Button'
    export let action = null // ButtonProps
    export let title = null // ButtonProps
    export let subtitle = null // string
    export let content = null // ButtonProps
    export let centerContent = false //bool

    let className
    export { className as class }

    const dispatch = createEventDispatcher()

    const events = {
        click: event => {
            dispatch('action', event.detail)
        }
    }
    const handleEvent = (event, ...args) =>
        events[event.type] ? events[event.type](event, ...args) : {}
</script>

<style global>
    :global(.ActionCard) {
        position: relative;
        flex: 1 0 auto;
    }

    :global(.ActionCard-title) {
        line-height: 2;
        font-weight: var(--font-weight-light);
        font-size: var(--text-size-heading2);
        margin-bottom: 10px;
    }

    :global(.ActionCard-subtitle) {
        margin-bottom: 24px;
        text-align: center;
        display: block;
        font-size: var(--text-size-large);
        font-weight: var(--font-weight-normal);
    }

    :global(.ActionCard-button) {
        margin: var(--card-padding) calc(var(--card-padding) * -1)
            calc(var(--card-padding) * -1);
        border-radius: 0 0 2em 2em;
        border-radius: 0 0 var(--border-radius, 2em) var(--border-radius, 2em);
    }

    :global(.ActionCard-content) {
        display: flex;
        flex: 1 0 auto;
        justify-content: space-between;
    }

    :global(.ActionCard-content--centered) {
        justify-content: center;
        align-items: center;
    }

    :global(.ActionCard) :global(UL)
    {
        padding:0px;
    }

    :global([dir="ltr"]) :global(.ActionCard) :global(UL) :global(LI) {
        padding-left: 0px;
}

    :global([dir="rtl"]) :global(.ActionCard) :global(UL) :global(LI) {
        padding-right: 0px;
}

    :global(.ActionCard) :global(UL) :global(LI) {
        text-align: left;
        list-style: none;
        margin-bottom: 10px;
    }</style>

{#if subtitle}
    <h3 class="ActionCard-subtitle">{subtitle}</h3>
{/if}

<Card rounded bordered center class={classNames('ActionCard', className)}>
    {#if title}
        <h2 class="ActionCard-title">{title.text || title}</h2>
    {/if}
    <div class="ActionCard-content" class:ActionCard-content--centered={centerContent}>
        <slot>{content || ''}</slot>
    </div>
    {#if action}
        <Button
            {...action}
            on:click={handleEvent}
            size="large"
            class={classNames('ActionCard-button', action.class)} />
    {/if}
</Card>
