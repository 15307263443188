<script>
    import marked from 'marked'
    import { stores } from '@sapper/app'
    import PageBlock from 'components/PageBlock'
    import Title from 'components/Title'

    const { page } = stores()

    const pageUrl = `https://${$page.host}${$page.path}`

    export let block
    export let blockIndex
    export let withTransparentBackground = false
    export let centerTitle = true
</script>

<style global>
    
:global(.HowToBlock) {
}

:global(.HowToBlock) :global(a) {
    font-weight: var(--font-weight-bold);
}

:global(.HowToBlock) :global(ol) {
    padding-left: 1em;
}

:global(.HowToBlock) :global(li) {
}

:global(li.HowToBlock-step) + :global(li.HowToBlock-step) 
{
    padding-top:20px;
}</style>

<PageBlock class="HowToBlock" {withTransparentBackground} {centerTitle} withoutBackground>
    <div itemscope itemtype="http://schema.org/HowTo">
        <div itemprop="name">
            <Title align={(centerTitle && 'center')} level={blockIndex == 0 ? 1 : 2}>{block.title}</Title>
        </div>
        {#if block.text_md}
            <p>
                {@html marked(block.text_md || '')}
            </p>
        {/if}
        <ol>
            {#each block.items as step, i}
                <li
                    class="HowToBlock-step"
                    id="step{i}"
                    itemprop="step"
                    itemscope
                    itemtype="http://schema.org/HowToStep">
                    <link itemprop="url" href="{pageUrl}#step{i}" />
                    <span itemprop="text">
                        {@html marked(step.step_md || '')}
                    </span>
                </li>
            {/each}
        </ol>
    </div>
</PageBlock>
