<script context="module">
    const getPreloadArgs = ({
        filter_by_skill,
        filter_by_file_type,
        filter_by_tag,
        item_count
    }) => {
        let args = ''
        if (filter_by_skill && filter_by_skill.length) {
            args = `(by_skill-${filter_by_skill})`
        } else if (filter_by_file_type && filter_by_file_type.length) {
            args = `(by_file_type-${filter_by_file_type})`
        }
        if (filter_by_tag && filter_by_tag.length) {
            args += `(by_tag-${filter_by_tag})`
        }

        args += `(take-${getTakeCount(item_count)})`

        args = args.length ? args : 'all'
        return args
    }

    function getTakeCount(itemCount) {
        if (typeof itemCount === 'undefined') {
            return 3
        }

        if (parseInt(itemCount) == 0) {
            return 100
        }

        return itemCount
    }

    export async function preload(block, fetch) {
        const args = getPreloadArgs(block)
        const data = await fetch(`blog/latest-${args}.json`, {
            credentials: 'include'
        })
        const { items } = await data.json()
        return { posts: items }
    }
</script>

<script>
    import PageBlock from 'components/PageBlock'
    import BlogCard from 'components/BlogCard.svelte'

    export let block
    export let centerTitle = true
    export let columns = { l: 10, s: 12 }
    export let withImagePattern = true
    export let imageDirection
    export let show_excerpt = true

    let {
        hide_excerpt,
        hide_published,
        posts = [],
        title,
        subtitle,
        ...blockData
    } = block

    hide_excerpt = !show_excerpt

    let imagePos = index => index % 2

    if (imageDirection == 'left') {
        imagePos = index => false
    } else if (imageDirection == 'right') imagePos = index => true
</script>

<style>
    .BlogLatestBlock-more {
        margin: var(--layout-gutter) auto 0 auto;
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0.6em 1em 0.6em;
    }</style>

{#if posts.length}
    <PageBlock
        class="BlogLatestBlock"
        {...blockData}
        {columns}
        {title}
        {subtitle}
        {centerTitle}>
        {#each posts as post, index (post.id)}
            <BlogCard
                imageRight={imagePos(index)}
                {post}
                {index}
                hideExcerpt={hide_excerpt}
                hidePublished={hide_published}
                {withImagePattern}
                {imageDirection} />
        {/each}

        {#if block.more_link && block.more_link.cached_url}
            <div class="text-align-center">
                <a
                    href={block.more_link.cached_url}
                    class="Button Button--withTheme theme-dark-blue-light
                    BlogLatestBlock-more">
                    Read More
                </a>
            </div>
        {/if}
    </PageBlock>
{/if}
