<script>
    import Article from 'components/Article'
    import Accordion from 'components/Accordion'
    import { Grid, GridCell } from 'components/Grid'
    import Card from 'components/Card'
    import Title from 'components/Title'

    export let block

    let { title, ...blockData } = block
</script>

<style global>
    :global(.FeaturesBlock-header) {
        margin-bottom: calc(var(--layout-gutter) * 2);
    }</style>

<Article
    class="Features-Block"
    {...blockData}
    withTransparentBackground={true}
    columns={{ l: 8, s: 12 }}>
    <header class="FeaturesBlock-header" slot="header">
        <Title level={2} align={'center'}>{title}</Title>
    </header>

    <div class="s-hidden">
        {#each block.features as { title, ...feature }, index}
            <Accordion large center {title} isOpen={index === 0}>
                <Card
                    center
                    plain
                    {...feature}
                    icon={{ type: feature.icon, size: 'feature' }}>
                    {feature.description}
                </Card>
            </Accordion>
        {/each}
    </div>
    <Grid withGap class="maxs-hidden">
        {#each block.features as feature}
            <GridCell widths={{ s: '1of2' }}>
                <Card
                    center
                    plain
                    {...feature}
                    icon={{ type: feature.icon, size: 'feature' }}>
                    {feature.description}
                </Card>
            </GridCell>
        {/each}
    </Grid>
</Article>
