<script>
    import Link from 'components/Link'

    export let quote // string
    export let source // string
    export let sourceUrl // string
    export let name // string
    export let profession // string
    export let theme // string
</script>

<style>
    .Blockquote {
        padding: 2em 5rem;
        margin: 0;
        text-align: center;
        max-width: 100%;
    }
    .Blockquote-quote {
        font: var(--text-size-quote);
        max-width: 862px;
        margin: 0 auto 80px auto;
    }
    .Blockquote-quote::before {
        content: open-quote;
    }
    .Blockquote-quote::after {
        content: close-quote;
    }
    .Blockquote-cite {
        margin-top: 1em;
        font-style: normal;
    }
    .Blockquote-source {
        display: block;
    }
    .Blockquote-name {
        display: block;
    }
    .Blockquote-profession {
        display: block;
        font-weight: var(--font-weight-light);
    }</style>

<blockquote
    class={['Blockquote', theme && `theme-${theme}`].filter(Boolean).join(' ')}>
    <p class="Blockquote-quote">
        <slot>{quote}</slot>
    </p>
    {#if name || source || profession}
        <cite class="Blockquote-cite">
            {#if source}
                {#if sourceUrl}
                    <Link
                        class="Blockquote-source"
                        to={sourceUrl}
                        text={source} />
                {:else}
                    <span class="Blockquote-source">{source}</span>
                {/if}
            {/if}
            {#if name}
                <span class="Blockquote-name">{name}</span>
            {/if}
            {#if profession}
                <span class="Blockquote-profession">{profession}</span>
            {/if}
        </cite>
    {/if}
</blockquote>
