<script>
    import marked from 'marked'
    import PageBlock from 'components/PageBlock'
    import Grid from 'components/Grid'
    import GridCell from 'components/Grid/GridCell.svelte'
    import Image from 'components/Image'
    import Link from 'components/Link'
    import { Gutter, GutterItem } from 'components/Gutter'
    export let block
</script>

<style global>
    :global(.TeamBlock-image) {
        width: 190px;
        height: 190px;
        margin-bottom: 10px;
    }

    :global(.TeamBlock-link) {
        font-weight: var(--font-weight-light);
        text-align: center;
        text-decoration: none;
        font-size: 24px;
    }

    :global(.TeamBlock-title) {
        text-align: center;
        display: block;
        font-weight: var(--font-weight-bold);
        font-size: 18px;
    }</style>

<PageBlock
    title={block.title}
    subtitle={block.subtitle}
    columns="8"
    extraPaddingIfLast>
    <Gutter align="center">
        {#each block.members as member}
            <GutterItem>
                <Image
                    lazy
                    class="TeamBlock-image"
                    dimension={'1by1'}
                    circle
                    cover
                    align="center"
                    src={member.image}
                    alt={member.name} />
                {#if member.linkedin_url}
                    <Link to={member.linkedin_url} class="TeamBlock-link" block>
                        {member.name}
                    </Link>
                {:else}
                    <strong>{member.name}</strong>
                {/if}
                <div class="TeamBlock-title">{member.title}</div>
            </GutterItem>
        {/each}
    </Gutter>
</PageBlock>
