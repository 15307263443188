<script>
    import classNames from 'classnames'
    export let fill = false
    let className
    export { className as class }
</script>

<div class={classNames('PropertyList', className, { 'PropertyList--fill': fill })}>
    <slot/>
</div>

<style>
    .PropertyList--fill {
        width: 100%;
    }</style>