<script>
    import { createEventDispatcher } from 'svelte'
    import classNames from 'classnames'
    import Card from 'components/Card'
    import Button from 'components/Button'
    
    export let action = null // ButtonProps
    export let subtitle = null // string
    export let content = null // ButtonProps
    export let centerContent = false //bool
    export let noBorder = false //bool
    export let labels = false //bool
    export let outsideLabels = false //bool

    let className
    export { className as class }

    const dispatch = createEventDispatcher()

    const events = {
        click: event => {
            dispatch('action', event.detail)
        }
    }
    const handleEvent = (event, ...args) =>
        events[event.type] ? events[event.type](event, ...args) : {}
</script>

<h3 class="PricingCard-subtitle">{subtitle || ''}</h3>

<Card rounded bordered={!noBorder} center class={classNames('PricingCard', {'PricingCard-labels' : labels, 'PricingCard-outsidelabels' : outsideLabels}, className)}>   
    <h2 class="PricingCard-title">
        <slot name="title"></slot>
    </h2>
    <div class="PricingCard-content" class:PricingCard-content--centered={centerContent}>
        <slot>{content || ''}</slot>
    </div>
    {#if action}
        <Button
            {...action}
            on:click={handleEvent}
            size="large"
            class={classNames('PricingCard-button', action.class)} />
    {/if}
</Card>

<style global>
    :global(.PricingCard) {
        position: relative;
        flex: 1 0 auto;
    }

    :global(.PricingCard-title) {
        line-height: 2;
        font-weight: var(--font-weight-light);
        font-size: var(--text-size-heading2);
        margin-bottom: 10px;
    }

    :global(.PricingCard-subtitle) {
        margin-bottom: 24px;
        text-align: center;
        display: block;
        font-size: var(--text-size-large);
        font-weight: var(--font-weight-normal);
    }

    :global(.PricingCard-subtitle:empty::after) {
        content: "\200B";
        visibility: hidden;
    }

    :global(.PricingCard-button) {
        margin: 0 calc(var(--card-padding) * -1)
            calc(var(--card-padding) * -1);
        border-radius: 0 0 2em 2em;
        border-radius: 0 0 var(--border-radius, 2em) var(--border-radius, 2em);
    }

    :global(.PricingCard-content) {
        display: flex;
        flex: 1 0 auto;
        justify-content: space-between;
    }

    :global(.PricingCard-content--centered) {
        justify-content: center;
        align-items: center;
    }

    :global(.PricingCard) :global(UL)
    {
        padding:0px;
    }

    :global([dir="ltr"]) :global(.PricingCard) :global(UL) :global(LI) {
        padding-left: 0px;
}

    :global([dir="rtl"]) :global(.PricingCard) :global(UL) :global(LI) {
        padding-right: 0px;
}

    :global(.PricingCard) :global(UL) :global(LI) {
        text-align: center;
        list-style: none;
        margin-bottom: 10px;
    }

    :global(.PricingCard) :global(UL:first-child) :global(LI) {
        text-align: right;
    }

    @media (max-width: 767px){
       :global(.PricingCard) :global(UL:first-child) {
         width: 50%;
        }
        :global(.PricingCard) :global(UL:nth-child(2)) {
            width: 40%;
        }
    }

    @media (min-width: 768px){
        :global(.PricingCard-content) {
            justify-content: center;
        }

        :global(.PricingCard-outsidelabels) :global(ul.descriptions-s){
            display: block;
            position: absolute;
            left: -170px;
        }
    }

    :global(.PricingCard-labels) :global(.PricingCard-content)  {
        justify-content: flex-end;
    }

    :global(.PricingCard-labels) {
        padding: var(--card-padding);
        padding-right: 0;
    }</style>
