<script>
    import { goto } from '@sapper/app'
    import classNames from 'classnames'
    import { createEventDispatcher } from 'svelte'

    import { Input } from './index'
    import { List, ListItem } from 'components/List'

    export let searchHits
    export let placeholder
    export let value = null

    let selectedSearchHitIndex
    const dispatch = createEventDispatcher()

    $: {
        dispatch('searchphrase', {
            searchPhrase: value
        })
    }

    function highlight(input, keyword) {
        return keyword.replace(new RegExp(input, 'ig'), (s) => `<b>${s}</b>`)
    }

    function onKeydown(event) {
        if (searchHits && searchHits.length) {
            switch (event.key) {
                case 'ArrowDown':
                    if (
                        selectedSearchHitIndex >= 0 &&
                        selectedSearchHitIndex < searchHits.length - 1
                    ) {
                        selectedSearchHitIndex++
                    } else {
                        selectedSearchHitIndex = 0
                    }
                    break
                case 'ArrowUp':
                    if (selectedSearchHitIndex == 0) {
                        selectedSearchHitIndex = searchHits.length - 1
                    } else if (selectedSearchHitIndex > 0) {
                        selectedSearchHitIndex--
                    } else {
                        selectedSearchHitIndex = 0
                    }
                    break
                case 'Enter':
                    if (selectedSearchHitIndex >= 0) {
                        event.preventDefault()
                        value = searchHits[selectedSearchHitIndex].keyword

                        goto(searchHits[selectedSearchHitIndex].slug)
                    }
                    break
            }
        }
    }
</script>

<style global>
    :global(.SearchInput) :global(.Input-searchHitItem) {
        padding: 0.6875em 2em;
        cursor: pointer;
        display: flex;
    }

    :global(.SearchInput--active) :global(.Input-input) {
        border-radius: 0.75em 0.75em 0 0;
    }

    :global(.SearchInput) :global(.Input-searchHitItem:last-child) {
        padding-bottom: 1.75em;
    }

    :global(.SearchInput) :global(.Input-searchHitItemContent) {
        flex-grow: 1;
    }

    :global(.SearchInput) :global(.Input-searchHits) {
        overflow: hidden;
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        top: 100%;
        margin-top: -0.75em;
        padding-top: 0.75em;
        border-radius: 0 0 0.75em 0.75em;
        box-shadow: var(--box-shadow);
        background: var(--color-white);
        display: block;
    }

    :global(.Input-searchHitItem--selected),
    :global(.Input-searchHitItem:hover) {
        background: rgba(0, 0, 0, 0.1);
    }

    :global(.SearchInput) :global(.Input-wrapper) {
        border: none;
        border-radius: 0.75em 0.75em;
        box-shadow: var(--box-shadow);
    }

    :global(.SearchInput) :global(.Input-input) {
        padding: 1.75em 2em;
    }</style>

<div class="SearchInput SearchInput--active">
    <Input
        fill
        large
        {placeholder}
        bind:value
        on:keydown={onKeydown}
        attributes={{ 'aria-label': placeholder, autocomplete: 'off', autocorrect: 'off', autocapitalize: 'off', spellcheck: 'false' }}>

        <div class="Input-searchHits">
            <List class="Input-searchHitList">
                {#each searchHits as { keyword, slug }, index}
                    <ListItem
                        class={classNames('Input-searchHitItem', {
                            'Input-searchHitItem--selected':
                                selectedSearchHitIndex === index
                        })}>

                        <div
                            class="Input-searchHitItemContent"
                            on:click={() => {
                                selectedSearchHitIndex = index
                                value = searchHits[index].keyword
                                goto(slug)
                            }}>
                            {@html highlight(value, keyword)}
                        </div>

                        {#if index == searchHits.length - 1}
                            <slot name="suggestionButton" />
                        {/if}
                    </ListItem>
                {/each}
            </List>
        </div>

    </Input>
</div>
