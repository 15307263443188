<script>
    import { globalStore } from 'stores.js'
    import { formatNumber } from 'utils/utils.js'
    import { elasticOut } from 'svelte/easing'
    import { onMount } from 'svelte'

    export let metric

    let global = $globalStore
    let counterVisible = false


    function countUp(node, { countFrom, countTo }) {
        const duration = 2000

        var diff = countTo - countFrom

        return {
            duration,
            tick: t => {
                node.textContent = formatNumber(countFrom + ~~(diff * t))
            },
            easing: elasticOut
        }
    }


    onMount(() => {
        counterVisible = true
    })
</script>

<style>
    .HeroMetric {
        opacity: 0.5;
        font-size: 1.5rem;
    }</style>

{#if metric !== 'hide' && global.stats }
    <p class="HeroMetric text-size-large no-margin">
    {#if counterVisible}<span in:countUp={{ countTo: global.stats.TotalProcessedFiles, countFrom: global.stats.TotalProcessedFiles - 200 }} />
    {:else} <span>{formatNumber(global.stats.TotalProcessedFiles - 200)}</span>
    {/if} files converted
    </p>
{/if}