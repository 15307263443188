<script>
    import classNames from 'classnames'
    import Icon from 'components/Icon'
    export let text = ''
    let htmlFor = ''
    let className = ''
    export let icon = null
    export { className as class, htmlFor as for }
</script>

<style global>
    :global(.Label) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 0.5em;
        padding: 0;
        cursor: pointer;
    }
    :global(.Label-icon) {
        flex: 0 0 auto;
        margin-right: 1em;
    }
    :global(.Label-text) {
        flex: 1 0 auto;
    }</style>

<label class={classNames('Label', 'text-label', className)} for={htmlFor}>
    {#if icon}
        <Icon
            {...icon}
            type={icon.type || icon}
            class={classNames('Label-icon', icon.class)} />
        <span class="Label-text">
            <slot>{text}</slot>
        </span>
    {:else}
        <slot>{text}</slot>
    {/if}
</label>
