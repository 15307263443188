<script>
    import classNames from 'classnames'
    export let noborder = false; // boolean
</script>

<div class="PropertyRow">
    <div class="PropertyRow-name">
        <slot name="name"/>
    </div>
    <div class="PropertyRow-value">
        <slot name="value"/>
    </div>
</div>

<div class={classNames('PropertyRow-border', {
    'PropertyRow--noborder': noborder
})}></div>

<style>

    .PropertyRow-border {
        border-bottom: 2px dotted gray;
        height: 2px;
    }

    .PropertyRow--noborder {
        border: none !important;
    }

    .PropertyRow {
        position: relative;
        height: 50px;
    }

    .PropertyRow-name, .PropertyRow-value {
        position:absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .PropertyRow-name {
        font-weight: var(--font-weight-bold);
        text-align: left;
        left:0;
    }

    .PropertyRow-value {
        position:absolute;
        right:0;
        text-align: right;
    }</style>
