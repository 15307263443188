<script>
    import HeroMetrics from 'components/HeroMetric.svelte'
    import Hero from 'components/Hero'
    import { SearchInput } from 'components/Form'
    import searchService from 'services/keywordSearchService'
    import Button from 'components/Button'

    export let block

    const debounceMs = 300
    const defaultSearchHits = block.search_suggestions
        .split('\n')
        .map(entry => entry.trim().split('|'))
        .map(([keyword, slug]) => ({
            keyword: keyword.trim(),
            slug: slug
        }))

    let timeout = undefined
    let searchHits = defaultSearchHits

    async function fetchSearchResults(event) {
        const { searchPhrase } = event.detail
        if (!searchPhrase || !searchPhrase.trim().length) {
            clearTimeout(timeout)
            searchHits = defaultSearchHits
            return
        }

        try {
            clearTimeout(timeout)
            timeout = setTimeout(async () => {
                const result = await searchService.freeTextSearch(searchPhrase)
                searchHits =
                    result && result.length ? result : defaultSearchHits
                window.ga('send', 'event', 'search', 'search_performed', searchPhrase)
            }, debounceMs)
        } catch (ex) {
            console.error('Failed to fetch keywords', ex)
        }
    }

</script>

<style global>
    :global(.HeroHomeBlock-title) {
        margin-bottom: 0px;
        line-height: 75px;
        font-size: var(--text-size-heading1-hero);
        font-weight: var(--font-weight-light);
    }

    :global(.HeroHomeBlock-titleBox) {
        display: table;
        margin-top: 1rem;
        margin-bottom: 2.6875rem;
        margin-left: 2rem;
        min-height: 7rem;
    }

    :global(.HeroHomeBlock-bottomSpacer) {
        height: 25vh;
    }

    :global(.HeroHomeBlock-suggestLink) {
        margin: 0;
    }

    @media (max-width: 767px) {
        :global(.HeroHomeBlock-titleBox) {
            min-height: inherit;
        }
    }</style>

<Hero blank class="HeroHomeBlock">
    <div class="HeroHomeBlock-titleBox">
        <HeroMetrics metric={block.metric} />
        <h1 class="HeroHomeBlock-title">{block.title}</h1>
    </div>

    <SearchInput
        placeholder={block.search_placeholder}
        {searchHits}
        on:searchphrase={fetchSearchResults}>
        <div slot="suggestionButton">
            <Button plain class="HeroHomeBlock-suggestLink" to="https://airtable.com/shrsBOx7Sq2K4a3BH">
                Suggest a skill
            </Button>
        </div>
    </SearchInput>
    <div class="HeroHomeBlock-bottomSpacer" />
</Hero>
