<style global>
    :global(.Downloaded) {
        position: fixed;
        border-radius: 50%;
        background: var(--color-white);
        z-index: 1100;
        top: 0.5rem;
        right: 0.5rem;
        padding: 1rem;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: var(--box-shadow);
    }
    :global(.DownloadedIcon) {
        width: 3rem;
        height: 3rem;
    }
    :global(.DownloadedIcon) :global(path) {
        fill: var(--color-dark-blue);
    }</style>

<div class="Downloaded">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        class="DownloadedIcon"
        viewBox="0 0 20 20">
        <path d="M17 12v5H3v-5H1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5z" />
        <path d="M10 15l5-6h-4V1H9v8H5l5 6z" />
    </svg>
</div>
