<script>

import Card from 'components/Card'
import Pill from 'components/Pill'

export let image = null // StoryblokImage
export let title = null // string
export let description = null // string
export let tags = null // string[]
    
</script>

<Card shadowed>   
   
    <div>
        <img src={image.filename} alt={title} class="GalleryCard-image"/>

        <h3>
            {title}
            {#each tags as tag}
                <Pill size=small theme=pink class="GalleryCard-pill">{tag}</Pill>
            {/each}
        </h3>

    </div>

    <div class="GalleryCard-description">
        {description}
    </div>

    <slot/>

</Card>

<style global>

:global(.GalleryCard) :global(h3)
{
    display: flex;
    align-items: center;
}

:global(.GalleryCard-description) {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 150px;
    overflow: hidden;
    font-size: 1em;
}

:global(.GalleryCard-image) {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
}

:global(.GalleryCard-pill) {
    position:relative;
    top:-0.25em;
}</style>
