<script>
    import classNames from 'classnames'
    import Icon from 'components/Icon'
    import { createEventDispatcher } from 'svelte'

    export let description // string | null
    export let disabled // boolean | null
    export let checked = false //boolean | null
    let className = '' // string | null
    export let theme // string | null
    export let size = null // small
    export { className as class }

    const dispatch = createEventDispatcher()

    const setChecked = e => {
        if (disabled) {
            e.preventDefault()
            return
        }
        const newValue = Boolean(e.target.value)
        checked = newValue
        dispatch('change', { value: newValue })
    }
</script>

<style global>
    :global(.Switch) {
        position: relative;
        display: inline-block;
        width: 4em;
        height: 2.5em;
        line-height: 2.5em;
    }

    :global(.SwitchButton) {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 0.4s;
        border-radius: 2em;
    }

    :global(.SwitchButton:before) {
        position: absolute;
        content: '';
        height: 2em;
        width: 2em;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
    }

    :global(.Switch) :global(input) {
        opacity: 0;
        width: 0;
        height: 0;
    }

    :global(.Switch:not(.Switch--disabled)) :global(span) {
        background-color: var(--color-dark-blue);
    }

    :global(.Switch--disabled) :global(span) {
        background-color: var(--color-grey);
    }

    :global(.SwitchInput:checked) + :global(.SwitchButton:before) {
        transform: translateX(1.5em);
    }

    :global(.Switch--small) {
        height: 1.5em;
        line-height: 1.45em;
        width: 2.4em;
    }

    :global(.Switch--small) :global(.SwitchButton) {
        border-radius: 1.2em;
    }

    :global(.Switch--small) :global(.SwitchButton:before) {
        height: 1.2em;
        width: 1.2em;
        left: 0.19em;
        bottom: 0.185em;
    }

    :global(.Switch--small) :global(.SwitchInput:checked) + :global(.SwitchButton:before) {
        transform: translateX(0.85em);
    }</style>

<label
    class={classNames('Switch', className, {
        [`Switch--themed theme-${theme}`]: typeof theme === 'string',
        'Switch--disabled': disabled,
        [`Switch--${size}`]: size
    })}>
    <input
        class="SwitchInput"
        type="checkbox"
        on:click={setChecked}
        bind:checked
        {disabled} />
    <span class="SwitchButton" title={description} />
</label>
