<script>
    import PageBlock from 'components/PageBlock'
    import Button from 'components/Button'

    export let block
</script>

<style global>
    :global(.CtaBlock) {
        text-align: center;
        margin-left: 0;
        margin-right: 0;
    }</style>

<PageBlock
    class="CtaBlock"
    title={block.title}
    subtitle={block.subtitle} 
    theme="turquoise"
    fill>
    <Button size="large" to={block.link && block.link.cached_url || '/download/'} theme="blue-main">
        {block.cta}
    </Button>
</PageBlock>
