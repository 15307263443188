<script>
    export let block

    import { onMount } from 'svelte'
    import PageBlock from 'components/PageBlock'
    import { Grid, GridCell } from 'components/Grid'
    import { Gutter, GutterItem } from 'components/Gutter'
    import ActionCard from 'components/ActionCard'
    import PricingCard from 'components/PricingCard'
    import { RadioList } from 'components/Form'
    import Icon from 'components/Icon'
    import Pill from 'components/Pill'
    import Switch from 'components/Switch'
    import productService from 'services/productService'
    import { formatNumber } from 'utils/utils.js'

    let products = []
    let planFree = {}
    let personalPlan = {}
    let personalMonthly = {}
    let personalYearly = {}
    let professionalPlan = {}
    let professionalMonthly = {}
    let professionalYearly = {}
    let yearly = false
    let selectedPlan = "FREE"
    let discount = 0

    onMount(async () => {
        let response = await productService.getProducts()
        products = response.Products
        planFree = products.find(p => p.Name == 'Free')
        personalMonthly = products.find(p => p.Key == 'PERSONAL_1M_UNLIMITED')
        personalYearly = products.find(p => p.Key == 'PERSONAL_1YR_UNLIMITED')
        professionalMonthly = products.find(p => p.Key == 'PROFESSIONAL_1M_UNLIMITED')
        professionalYearly = products.find(p => p.Key == 'PROFESSIONAL_1YR_UNLIMITED')
        
        personalPlan = personalMonthly
        professionalPlan = professionalMonthly

        discount = Math.round(
            ((personalMonthly.PriceInUsd * 12 - personalYearly.PriceInUsd) * 100) /
                (personalMonthly.PriceInUsd * 12)
        )
    })

    const getPersonalMonthlyPrice = plan => {
        return plan.Key == 'PERSONAL_1M_UNLIMITED' ? personalMonthly.PriceInUsd : (personalYearly.PriceInUsd)/12
    }

    const getProfessionalMonthlyPrice = plan => {
        return plan.Key == 'PROFESSIONAL_1M_UNLIMITED' ? professionalMonthly.PriceInUsd : (professionalYearly.PriceInUsd)/12
    }

    const changeTerm = () => {
        if (personalPlan.Key == 'PERSONAL_1M_UNLIMITED') {
            personalPlan = personalYearly
        } else {
            personalPlan = personalMonthly
        }
        if (professionalPlan.Key == 'PROFESSIONAL_1M_UNLIMITED') {
            professionalPlan = professionalYearly
        } else {
            professionalPlan = professionalMonthly
        }
    }
    const togglePaid = () => {}
</script>

<style>
    .TogglePlan {
        display: flex;
    }
    
    .TogglePlan > div {
        width: 50%;
        color: #fff;
        padding: 10px 0;
        text-align: center;
    }
    
    .TogglePlan > div {
        background: #707190;
        cursor: pointer;
    }
    
    .TogglePlan > div.Selected {
        background: #010357;
    }
    
    .TogglePlan > div:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    
    .TogglePlan > div:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .plan-cancel {
        margin-top: 20px;
        font-weight: bold;
    }
    
    span.pricing-term-label {
        color: #898989;
        font-size: 0.5em;
    }
    
    LI span.pricing-term-label {
        font-size: 0.8em;
    }</style>

<PageBlock columns={{ l: 6, s: 12 }}>
    <Grid withGap sameHeight />
</PageBlock>

<PageBlock {...block} centerTitle columns={{ l: 8, s: 12 }}>
    <Grid withGap sameHeight>

        <GridCell class="m-hidden">
            <div class="TogglePlan">
                <div
                    id="TogglePlanFree"
                    class={selectedPlan == "FREE" ? '' : 'Selected'}
                    on:click={() => (selectedPlan = "FREE")}>
                    Free
                </div>
                <div
                    id="TogglePlanPersonal"
                    class={selectedPlan == "PERSONAL" ? '' : 'Selected'}
                    on:click={() => {
                        selectedPlan = "PERSONAL" 
                    }}>
                    Personal
                </div>
                <div
                    id="TogglePlanProfessional"
                    class={selectedPlan == "PROFESSIONAL" ? '' : 'Selected'}
                    on:click={() => {
                        selectedPlan = "PROFESSIONAL"
                    }}>
                    Professional
                </div>
            </div>
        </GridCell>

        <GridCell>
            <p class="text-align-center term-switch">
                Monthly
                <Switch
                    size="small"
                    bind:checked={yearly}
                    on:change={changeTerm} />
                Yearly
                <Pill theme="pink" size="pricing">
                    {@html discount ? `-${discount}%` : '&nbsp;'}
                </Pill>
            </p>
        </GridCell>

        <GridCell
            widths={{ m: '1of3', l: '1of3' }}
            class={selectedPlan != "FREE" ? 'maxm-hidden' : ''}>
            <PricingCard
                outsideLabels
                subtitle={planFree.Name || ''}
                action={{ text: 'Download', to: '/download', theme: 'turquoise' }}>
                <span slot="title">$0</span>
                <ul class="descriptions-s m-hidden">
                    <li>Conversions</li>
                    <li><a href="/cloud-credits">Cloud Credits</a></li>
                    <li>Batch Processing</li>
                    <li>Commercial Use</li>
                    <li>Devices</li>
                    <li>File Types</li>
                    <li>Skills</li>
                </ul>
                <ul>
                    <li class="desc desc-monthlycredits">
                        {@html planFree.RecurringCredits || '&nbsp;'} &nbsp;<span class="pricing-term-label">/month</span>
                    </li>
                    <li class="desc">
                        -
                    </li>
                    <li class="desc">
                        {#if planFree.AllowBatchProcessing}
                            <Icon type="checkthick" />
                        {:else}-{/if}
                    </li>
                    <li class="desc">
                        {#if planFree.AllowCommercialUse}
                            <Icon type="checkthick" />
                        {:else}-{/if}
                    </li>
                    <li class="desc">
                        {@html planFree.Devices || '&nbsp;'}
                    </li>
                    <li class="desc">
                        {@html formatNumber(planFree.FileTypes || 0) || '&nbsp;'}
                    </li>
                    <li class="desc">
                        {@html formatNumber(planFree.Skills || 0) || '&nbsp;'}
                    </li>
                </ul>
            </PricingCard>
        </GridCell>

        <GridCell
            widths={{ m: '1of3', l: '1of3' }}
            class={selectedPlan != "PERSONAL" ? 'maxm-hidden' : ''}>
            <PricingCard
                subtitle={personalPlan.Name}
                action={{ text: 'Select', to: personalPlan.PurchaseUrl, theme: 'pink' }}>
                <span slot="title">
                    ${getPersonalMonthlyPrice(personalPlan) || ''}
                    <span class="pricing-term-label">
                        /month
                    </span>
                </span>
                <ul class="descriptions-s m-hidden">
                    <li>Conversions</li>
                    <li><a href="/cloud-credits">Cloud Credits</a></li>
                    <li>Batch Processing</li>
                    <li>Commercial Use</li>
                    <li>Devices</li>
                    <li>File Types</li>
                    <li>Skills</li>                    
                </ul>
                <ul>
                    <li title="Excluding skills that require cloud credits">{personalPlan.MonthlyCredits || 'UNLIMITED*'}</li>
                    <li class="desc">
                        {@html formatNumber(personalPlan.RecurringCredits || 0) || '&nbsp;'}&nbsp; <span class="pricing-term-label">/{(yearly ? 'year' : 'month')}
                    </li>
                    <li>
                        {#if personalPlan.AllowBatchProcessing}
                            <Icon type="checkthick" />
                        {:else}-{/if}
                    </li>
                    <li class="desc">
                        {#if personalPlan.AllowCommercialUse}
                            <Icon type="checkthick" />
                        {:else}-{/if}
                    </li>
                    <li class="desc">
                        {@html formatNumber(personalPlan.Devices || 0) || '&nbsp;'}
                    </li>
                    <li class="desc">
                        {@html formatNumber(personalPlan.FileTypes || 0) || '&nbsp;'}
                    </li>
                    <li class="desc">
                        {@html formatNumber(personalPlan.Skills || 0) || '&nbsp;'}
                    </li>
                    <li class="plan-cancel">Cancel Anytime</li>
                </ul>
            </PricingCard>
        </GridCell>

        <GridCell
            widths={{ m: '1of3', l: '1of3' }}
            class={selectedPlan != "PROFESSIONAL" ? 'maxm-hidden' : ''}>
            <PricingCard
                subtitle={professionalPlan.Name}
                action={{ text: 'Select', to: professionalPlan.PurchaseUrl, theme: 'dark-blue' }}>
                <span slot="title">
                    ${getProfessionalMonthlyPrice(professionalPlan) || ''}
                    <span class="pricing-term-label">
                        /month
                    </span>
                </span>
                <ul class="descriptions-s m-hidden">
                    <li>Conversions</li>
                    <li><a href="/cloud-credits">Cloud Credits</a></li>
                    <li>Batch Processing</li>
                    <li>Commercial Use</li>
                    <li>Devices</li>
                    <li>File Types</li>
                    <li>Skills</li>
                </ul>
                <ul>
                    <li title="Excluding skills that require cloud credits">{professionalPlan.MonthlyCredits || 'UNLIMITED*'}</li>
                    <li class="desc">
                        {@html formatNumber(professionalPlan.RecurringCredits || 0) || '&nbsp;'}&nbsp; <span class="pricing-term-label">/{(yearly ? 'year' : 'month')}</span>
                    </li>
                    <li>
                        {#if professionalPlan.AllowBatchProcessing}
                            <Icon type="checkthick" />
                        {:else}-{/if}
                    </li>
                    <li class="desc">
                        {#if professionalPlan.AllowCommercialUse}
                            <Icon type="checkthick" />
                        {:else}-{/if}
                    </li>
                    <li class="desc">
                        {@html formatNumber(professionalPlan.Devices || 0) || '&nbsp;'}
                    </li>
                    <li class="desc">
                        {@html formatNumber(professionalPlan.FileTypes || 0) || '&nbsp;'}
                    </li>
                    <li class="desc">
                        {@html formatNumber(professionalPlan.Skills || 0) || '&nbsp;'}
                    </li>
                    <li class="plan-cancel">Cancel Anytime</li>
                </ul>
            </PricingCard>
        </GridCell>
    </Grid>
</PageBlock>
