<script>
    import marked from 'marked'
    import classNames from 'classnames'
    import Title from 'components/Title'

    export let title = null
    export let subtitle = null
    export let content = null
    export let titleLevel = 2
    let className
    export { className as class }
</script>

<style global>
    :global(.Section-header) {
        margin-bottom: var(--layout-gutter);
        border-bottom: 1px solid;
    }
    :global(.Section-title) {
        text-align: left;
    }
    :global(.Section-subtitle) {
        text-align: left;
    }
    :global(.Section) + :global(.Section) {
        margin-top: var(--layout-gutter);
    }</style>

<section class={classNames('Section', className)}>
    {#if title || subtitle}
        <header class="Section-header">
            {#if title}
                <Title
                    class="Section-title"
                    level={titleLevel}
                    asLevel={5}
                    text={title} />
            {/if}
            {#if subtitle}
                <Title
                    class="Section-subtitle"
                    level={titleLevel + 1}
                    asLevel={5}
                    text={subtitle} />
            {/if}
        </header>
    {/if}
    <div class="Section-content">
        <slot>
            {#if content}
                {@html marked(content)}
            {/if}
        </slot>
    </div>
</section>
