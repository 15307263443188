<script>
    import classNames from 'classnames'
    import { onMount, afterUpdate } from 'svelte'
    import Slide from './Slide.svelte'
    import Button from 'components/Button'
    export let items = []
    export let controls = true
    export let activeIndex = 0
    let className
    export { className as class }

    let slideshowElement
    let wrapperElement
    let itemsElement
    let itemLength

    function scrollLeft() {
        scrollTo(activeIndex - 1)
    }
    function scrollRight() {
        scrollTo(activeIndex + 1)
    }
    function scrollTo(index, animate) {
        itemLength = itemsElement.children.length
        const newIndex = index < 0 ? itemLength + index : index % itemLength
        let minHeight = 0
        Array.prototype.forEach.call(itemsElement.children, (slide, index) => {
            slide.classList.remove(
                'Slide--previous',
                'Slide--active',
                'Slide--before',
                'Slide--after'
            )

            if (index === newIndex) {
                slide.classList.add('Slide--active')
            } else {
                slide.classList.add(
                    newIndex > index ? 'Slide--before' : 'Slide--after'
                )

                if (index === activeIndex) {
                    slide.classList.add('Slide--previous')
                }
            }
            minHeight =
                slide.clientHeight > minHeight ? slide.clientHeight : minHeight
        })
        wrapperElement.style.minHeight = `${minHeight}px`
        activeIndex = newIndex
    }
    onMount(() => scrollTo(activeIndex))
</script>

<style global>
    :global(.Slideshow) {
        position: relative;
    }
    :global(.Slideshow-wrapper) {
        overflow: hidden;
        position: relative;
        max-height: 100%;
        min-height: 60vh;
        transition: min-height 0.5s ease;
    }
    :global(.Slideshow-innerWrapper) {
        max-height: 100%;
    }
    :global(.Slideshow-items) {
    }
    :global(.Slide) {
        min-height: 250px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: none;
        transition: all 0.5s ease;
    }
    :global(.Slide--active) {
        display: block;
        position: relative;
        z-index: 1;
        transform: translateX(0);
    }
    :global(.Slide--previous) {
        z-index: 0;
        display: block;
    }
    :global(.Slide--before) {
        display: block;
        opacity: 0;
        transform: translateX(-100%);
    }
    :global(.Slide--after) {
        display: block;
        opacity: 0;
        transform: translateX(100%);
    }
    :global(.Slideshow--multiple) :global(.Slide) {
        width: auto;
    }
    :global(.Slideshow-controlLeft),
    :global(.Slideshow-controlRight) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        left: 0;
    }
    :global(.Slideshow-controlLeft) {
        left: 2%;
    }
    :global(.Slideshow-controlRight) {
        left: auto;
        right: 2%;
    }
    :global(.Slideshow-counter) {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    :global(.Slideshow-counterItem) {
        position: relative;
        cursor: pointer;
        width: 0.75em;
        height: 0.05em;
        margin: 0.5em;
        padding: 2em 0.5em 2em 0.5em;
        display: block;
    }
    :global(.Slideshow-counterItem::before) {
        content: '';
        position: absolute;
        bottom: 50%;
        border-bottom: 2px solid rgba(1, 3, 87, 0.24);
        width: 100%;
        z-index: 0;
        left: 0;
    }
    :global(.Slideshow-counterItem:hover:before) {
        content: '';
        position: absolute;
        bottom: 50%;
        border-bottom: 2px #0206a3 solid;
        width: 100%;
        z-index: 0;
        left: 0;
    }
    :global(.Slideshow-counterItem--selected:before) {
        border-bottom: 2px solid currentColor;
    }</style>

<div
    class={classNames('Slideshow', className, 'layout-fill')}
    bind:this={slideshowElement}>
    <div class="Slideshow-wrapper" bind:this={wrapperElement}>
        <div class="Slideshow-innerWrapper">
            <div class="Slideshow-items" bind:this={itemsElement}>
                <slot>
                    {#each items as item}
                        <Slide {...item} />
                    {/each}
                </slot>
            </div>
        </div>
    </div>
    {#if controls}
        <div class="Slideshow-controls">
            <div class="Slideshow-controlLeft">
                <Button
                    on:click={scrollLeft}
                    plain
                    icon="chevronleft"
                    hiddenText
                    text="Previous" />
            </div>
            <div class="Slideshow-counter">
                {#each Array(itemLength).fill(undefined) as __, index}
                    <span
                        role="button"
                        on:click={() => scrollTo(index)}
                        class={`Slideshow-counterItem ${activeIndex === index ? ' Slideshow-counterItem--selected' : ''}`}
                        aria-label="Slide {index + 1}
                        {activeIndex === index ? '(current)' : ''}" />
                {/each}
            </div>
            <div class="Slideshow-controlRight">
                <Button
                    on:click={scrollRight}
                    plain
                    icon="chevronright"
                    hiddenText
                    text="Next" />
            </div>
        </div>
    {/if}
</div>
