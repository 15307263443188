<script>
    import { globalStore } from 'stores.js'
    import Article from 'components/Article'
    import Title from 'components/Title'
    import SocialButtons from 'components/SocialButtons'
    import { Grid, GridCell } from 'components/Grid'
    import { Gutter, GutterItem } from 'components/Gutter'
    import ActionCard from 'components/ActionCard'

    export let block

    let global = $globalStore

</script>

<style global>
    :global(.ContactUsBlock-SocialButtons) {
        text-align: center;
        margin-bottom: 100px;
    }</style>

<Article class="ContactUsBlock" {...block} centerTitle>
    <Grid withGap sameHeight>
        <GridCell widths={{ m: '1of2', l: '1of2' }}>
            <ActionCard
                title="FAQ"
                subtitle="Instantaneus"
                action={{ text: 'FAQ', to: '/faq', theme: 'turquoise' }}>
                <p>
                    There is a good chance that someone already asked us your
                    brilliant question. We have gathered them here for you to
                    quickly find answers.
                </p>
            </ActionCard>
        </GridCell>
        <GridCell widths={{ m: '1of2', l: '1of2' }}>
            <ActionCard
                title="Email"
                subtitle="Within 24h"
                action={{ text: global.company_email, to: 'mailto:' + global.company_email, theme: 'pink' }}>
                <p>
                    If you’d rather contact us through your own e-mail, drop us
                    a note at {global.company_email}.
                </p>
            </ActionCard>
        </GridCell>
    </Grid>
</Article>
