<script>
    import Hero from 'components/Hero'
    import BlockRenderer from 'blocks/BlockRenderer'
    import { Grid, GridCell } from 'components/Grid'

    export let block
</script>

<style global>
    :global(.HeroBlock-column) > :global(*:first-child) {
        padding-top: var(--block-vertical-padding);
        margin-top: 0;
    }</style>

<Hero fill blank {...block}>
    <Grid reversedForSmall>
        <GridCell columns={{ l: 5 }} class="HeroBlock-column">
            {#each block.left_blocks as block, blockIndex}
                <BlockRenderer
                    {block}
                    {blockIndex}
                    blockCount={(block.left_blocks && block.left_blocks.length) || 0}
                    blockProps={{ centerTitle: false, withoutBackground: true, withoutTopPadding: true }} />
            {/each}
        </GridCell>

        <GridCell columns={{ l: 1 }} class="maxm-hidden" />

        <GridCell columns={{ l: 6 }} class="HeroBlock-column">
            {#each block.right_blocks as block, blockIndex}
                <BlockRenderer
                    {block}
                    {blockIndex}
                    blockCount={(block.right_blocks && block.right_blocks.length) || 0}
                    blockProps={{ aspectRatio: 1.5, withoutBackground: true, withoutTopPadding: true }} />
            {/each}
        </GridCell>
    </Grid>
</Hero>
