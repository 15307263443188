<script>
    import Icon from 'components/Icon'

    export let youtubeId
    export let coverImg
    export let coverAlt
    export let aspectRatio = 16 / 9

    let videoActivated = false
</script>

<style>
    .Video {
        position: relative;
        background: var(--color-white);
    }
    .Video::before {
        content: '';
        padding-bottom: calc(100% / (var(--aspect-ratio)));
        display: block;
    }
    .Video-iframe {
        position: absolute;
        -o-object-fit: contain;
           object-fit: contain;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .Video-coverBackground {
        position: absolute;
        -o-object-fit: cover;
           object-fit: cover;
        width: 100%;
        /* height: 100%; */
        left: 0;
        top: 0;
        box-shadow: var(--box-shadow);
    }
    .Video-playIconContainer {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 2.5em;
        height: 2.5em;
        background: none;
        color: var(--color-dark-blue);
        font-size: 2em;
        border: 2px solid var(--color-dark-blue);
        border-radius: 50%;
        transition: all 0.2s ease;
    }
    .Video-playIconContainer:hover {
        --play-hover-color: #0206a3;
        color: var(--color-white);
        border-color: var(--play-hover-color);
        background-color: var(--play-hover-color);
    }
    .Video-playIcon-icon {
        display: flex;
        margin: auto;
        transform: translateX(3px) scale(0.9);
    }</style>

<div class="Video" style="--aspect-ratio:{aspectRatio};">
    {#if !videoActivated}
        <div
            class="Video-cover"
            on:click={() => {
                videoActivated = true
            }}>
            <img src={coverImg} alt={coverAlt} class="Video-coverBackground" />

            <div class="Video-playIconContainer">
                <div class="Video-playIcon-icon">
                    <Icon type="play" />
                </div>
            </div>
        </div>
    {/if}
    {#if videoActivated}
        <iframe
            class="Video-iframe"
            id="videoplayer"
            src="https://www.youtube.com/embed/{youtubeId}?enablejsapi=1&modestbranding=1&rel=0&autoplay=1&showinfo=0"
            frameborder="0"
            allowfullscreen
            title={coverAlt} />
    {/if}
</div>
