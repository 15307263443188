<script context="module">
    import FaqListing from 'components/FaqListing.svelte'
    import PageBlock from 'components/PageBlock'
    import Title from 'components/Title'

    export async function preload(block, fetch) {
        let { questions = [] } = block
        const { items } = await fetch(`faq/faqs-${questions.join('_')}.json`, {
            credentials: 'include'
        }).then(data => data.json())
        return { questions: items }
    }
</script>

<script>
    export let block
</script>

<style global>
    :global(.FaqBlock-header) {
        margin-bottom: calc(var(--layout-gutter) * 2);
    }
    :global(.FaqBlock-more) {
        margin: var(--layout-gutter) auto 0 auto;
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0.6em 1em 0.6em;
    }</style>

<PageBlock columns={{ s: 12, l: 6 }} extraPaddingIfLast>
    {#if block.title}
        <Title level={2} align="center" class="FaqBlock-header">
            {block.title}
        </Title>
    {/if}
    <FaqListing questions={block.questions} />

    {#if block.more_link && block.more_link.cached_url}
        <a
            href={block.more_link.cached_url}
            class="Button Button--withTheme theme-dark-blue-light FaqBlock-more">
            Read More
        </a>
    {/if}
</PageBlock>
