<script>
    import PageBlock from 'components/PageBlock'
    import Blockquote from 'components/Blockquote'
    import Slideshow from 'components/Slideshow'
    import Slide from 'components/Slideshow/Slide.svelte'
    export let block
</script>

<style global>
    :global(.TestimonialsBlock) {
        margin-left: 0;
        margin-right: 0;
    }</style>

<PageBlock class="TestimonialsBlock" theme="turquoise" fill>
    <Slideshow>
        {#each block.testimonials as testimonial, i}
            <Slide>
                <Blockquote
                    sourceUrl={testimonial.source_link.cached_url}
                    {...testimonial}>
                    {testimonial.testimonial}
                </Blockquote>
            </Slide>
        {/each}
    </Slideshow>
</PageBlock>
